// Fonts:
$main-font: 'FbReformaNarrow-Regular';
$main-font_bold: 'FbReformaNarrow-Bold';
$main-font_light: 'FbReformaNarrow-Light';
$main-font_medium: 'FbReformaNarrow-Medium';
$open-sans: 'Open Sans', sans-serif;

// Media queries:
$mq-mobile_sm: 350;
$mq-mobile_md: 767;

$mq-mobile_sm: '(max-width: #{$mq-mobile_sm}px)';
$mq-mobile_md: '(max-width: #{$mq-mobile_md}px)';
