@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

@font-face {
	font-family: 'FbReformaNarrow-Bold';
	src: url('@client/assets/fonts/FbReformaNarrow-Bold.otf');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'FbReformaNarrow-Light';
	src: url('@client/assets/fonts/FbReformaNarrow-Light.otf');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'FbReformaNarrow-Medium';
	src: url('@client/assets/fonts/FbReformaNarrow-Medium.otf');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'FbReformaNarrow-Regular';
	src: url('@client/assets/fonts/FbReformaNarrow-Regular.otf');
	font-weight: normal;
	font-style: normal;
}
