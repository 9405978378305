@import '@client/styles/theme.scss';

:root {
  --loader-icon-shading-color: transparent;
}

.loader-icon {
  display: block;
  position: absolute;
  z-index: 2;
  left: 50%;
  top: 50%;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 3px solid white;
  border-left-color: transparent;
  transform: translate(-50%, -50%);

  animation-name: rotate;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;

  @keyframes rotate {
    0% { transform: translate(-50%, -50%) rotate(0deg); }
    100% { transform: translate(-50%, -50%) rotate(360deg); }
  }

  .shading {
    display: block;
    position: absolute;
    left: -4px;
    top: -4px;
    right: -4px;
    bottom: -4px;
    border-radius: 50%;
    background: linear-gradient(90deg, var(--loader-icon-shading-color) 15%, rgba(9, 69, 153, 0));
  }

  &.YesNo {
    .shading {
      display: none;
    }
  }
}