@import '@client/styles/mixins.scss';

.main {
	position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  &.offline {
    filter: grayscale(1);
  }
}