@import '@client/styles/mixins.scss';

.native-app-error {
  background-color: white;

	img {
    width: 100%;
    max-width: 720px;
  }
  a {
    opacity: 0;
    display: block;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
    text-align: center;
  }
  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    margin: auto;
    position: relative;
    max-width: 720px;
  }
}
