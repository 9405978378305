@import '@client/styles/mixins.scss';

.not-found {
	position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
}

.message {
  font-size: 50px;
}