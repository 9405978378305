@import '@client/styles/theme.scss';

.card {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.loader {
  position: absolute;
  z-index: 201;
  left: 50%;
  width: 44px;
  bottom: 0;
  opacity: 0;
  display: none;

  &::before {
    content: '';
    display: block;
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 50%;
    width: 100%;
    height: 44px;
    border-radius: 22px;
    background-color: inherit;
    transform: translate(-50%, -50%);
  }
}

.logo {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 210;
  top: 50%;
  left: 50%;
  width: 300px;
  height: 300px;
  transform: translate(-50%, -50%);

  img {
    display: block;
    max-width: 100%;
    max-height: 100%;
  }
}

.curtain {
  position: absolute;
  z-index: 200;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #111;

  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    height: 100%;
  }

  &::before {
    background: linear-gradient(0deg, #111, transparent);
    bottom: 100%;
  }
  
  &::after {
    background: linear-gradient(180deg, #111, transparent);
    top: 100%;
  }

  &-bg {
    position: relative;
    height: 100%;
    background-size: cover;
    background-position: center;
    opacity: 1;
    
    animation-name: zoom;
    animation-duration: 3.5s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;

    @keyframes zoom {
      0% {
        opacity: 0.2;
      }
      50% {
        opacity: 1;
      }
      100% {
        opacity: 0.2;
      }
    }
  }
}

.close-btn {
  position: fixed;
  display: block;
  z-index: 100000;
  left: 15px;
  top: 15px;
  width: 30px;
  height: 30px;
  background-color: black;
  border-radius: 50%;

  &-icon {
    position: absolute;
    width: 70%;
    height: 70%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) rotate(45deg);

    &::before,
    &::after {
      content: '';
      display: block;
      position: absolute;
      left: 50%;
      top: 50%;
      width: 100%;
      height: 2px;
      background-color: #707070;
      transform: translate(-50%, -50%) rotate(0);
    }

    &::after {
      transform: translate(-50%, -50%) rotate(90deg);
    }
  }
}

.credits {
  position: fixed;
  z-index: 100000;
  left: 0;
  right: 0;
  bottom: 4px;
  font-family: $main-font;
  text-align: center;
  font-size: 11px;
  color: white;

  a {
    color: inherit;
    text-decoration: underline;
    margin-right: 2px;
  }
}
