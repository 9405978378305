@import 'modern-css-reset/dist/reset.css';
@import 'theme';
@import 'mixins';

html {
  font-family: $main-font;
	font-size: 62.5%; // => 10px => 1rem
	scroll-behavior: smooth;

	-moz-text-size-adjust: none !important;
  -webkit-text-size-adjust: none !important;
  text-size-adjust: none !important;

	// Font variant
	-webkit-font-variant-ligatures: none;
	font-variant-ligatures: none;

	// Smoothing
	text-rendering: optimizeLegibility;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	font-smoothing: antialiased;
	text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;

	// Setting @viewport causes scrollbars to overlap content in IE11 and Edge, so
	// we force a non-overlapping, non-auto-hiding scrollbar to counteract.
	-ms-overflow-style: scrollbar;

	// Change the default tap highlight to be completely transparent in iOS.
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  &.full-vh {
    height: 100vh;
  }
}

html, body {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  touch-action: none;
  user-select: none;
  max-height: 5000em;
}

body {
  .full-vh & {
    height: 100%;
  }
}

#root {
  max-height: 5000em;
  .full-vh & {
    height: 100%;
  }
}

button {
  background: none;
  border: none;
  outline: none;
}

